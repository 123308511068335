import helpers from "../../../utils/helpers";

const Header = {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "version": "1.0.1",
  "source": "web"
}

const API_GATEWAY =
  helpers.API_ROUTES.BFF[process.env.REACT_APP_ENVIRONMENT || "error"];
const API_GATEWAY_V2 =
  helpers.API_ROUTES.BFFV2[process.env.REACT_APP_ENVIRONMENT || "error"];

const defaultTools: { [key: string]: unknown } = {
  mode: "cors",
  cache: "default",
  credentials: "include",
  headers: new Headers(Header)
}

const ToolsGet: any = {
  ...defaultTools,
  method: "GET"
};

const ToolsPost: any = {
  ...defaultTools,
  method: "POST"
};

// listo
const CheckOut = {
  async ApiCheckout() {
    return fetch(API_GATEWAY + "/checkout", ToolsGet).then((response: any) => {
      return response.json();
    });
  },
  // listo
  async updateProfile(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    ToolsPost.method = 'PATCH';
    return fetch(API_GATEWAY + "/checkout/attachments/profile", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async transaction(pPayload: any) {
    const toolsRequest = {
      ...defaultTools,
      body: JSON.stringify(pPayload),
      method: 'POST',
      headers: new Headers({
        ...Header,
        'source': '1',
        'method': 'transfer'
      })
    }

    return fetch(API_GATEWAY + '/checkout/transaction', toolsRequest)
      .then((response: any) => {
        return response.json();
      });
  },

  // listo
  async updateDeliveryWindow(pPayload: any) {
    const windows = {
      startDateUtc: pPayload.startDateUtc,
      endDateUtc: pPayload.endDateUtc
    };
    ToolsPost.body = JSON.stringify(windows);
    ToolsPost.method = 'PATCH';
    return fetch(API_GATEWAY + "/checkout/attachments/window", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  // listo
  async updateReceiver(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    ToolsPost.method = 'PATCH';
    return fetch(API_GATEWAY + "/checkout/attachments/shipping", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async updateCustomData(pPayload: any, custom: string) {
    const options = {
      ...defaultTools,
      body: JSON.stringify(pPayload),
      method: 'PATCH'
    }
    return fetch(API_GATEWAY + "/checkout/attachments/custom-data/" + custom, options)
      .then((response: any) => {
        return response.json();
      });
  },

  // listo
  async getCards() {
    return fetch(API_GATEWAY + "/cards/one-click", ToolsGet).then((response: any) => {
      return response.json();
    });
  },
  // listo
  async addCard(pPayload: any) {
    const options = {
      ...defaultTools,
      body: JSON.stringify(pPayload),
      method: 'POST'
    }
    return fetch(API_GATEWAY + "/cards/one-click", options).then(
      (response: any) => {
        return response.text()
      }
    );
  },
  // listo (faltan pruebas)
  async deleteCard(card_id: string) {
    const configTools = {
      ...defaultTools,
      method: 'DELETE'
    }
    return fetch(API_GATEWAY + `/cards/one-click/${card_id}`, configTools)
  },

  // listo
  async updateSelectedPaymentType(paymentSystem: any) {
    const options = {
      ...defaultTools,
      body: JSON.stringify({ paymentSystem }),
      method: 'PATCH'
    }

    return fetch(API_GATEWAY + `/checkout/attachments/payment`, options).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  // listo
  async generateCoupon(pPayload: any) {
    const options = {
      ...defaultTools,
      body: JSON.stringify(pPayload),
      method: 'PATCH'
    }

    return fetch(API_GATEWAY + "/checkout/coupon", options).then(
      (response: any) => {
        return response.json();
      }
    );
  },

  async updateBill(pPayload: any) {
    const options = {
      ...defaultTools,
      method: 'POST',
      body: JSON.stringify(pPayload)
    }
    return fetch(API_GATEWAY + `/checkout/invoice`, options).then((response: any) => {
      return response.json();
    });
  },

  async deleteBill() {
    const options = { ...defaultTools, method: 'DELETE' }
    return fetch(API_GATEWAY + `/checkout/invoice`, options)
  },

  async generateTransaction(Payload: any) {
    const toolsRequest = {
      ...ToolsPost,
      body: JSON.stringify(Payload),
      method: 'POST',
      headers: new Headers({
        ...Header,
        'source': '1',
        'method': 'one-click'
      })
    }

    return fetch(API_GATEWAY + `/checkout/transaction`, toolsRequest).then(
      (response: any) => {
        return response.json();
      }
    );
  },


  // no van ojo
  async giftCardBalance(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard/balance", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },
  async giftCardPayment(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },
  async deleteGiftCard(pPayload: any) {
    ToolsPost.body = JSON.stringify(pPayload);
    return fetch(API_GATEWAY + "/giftcard/deleteGiftCard", ToolsPost).then(
      (response: any) => {
        return response.json();
      }
    );
  },
  async getUser() {
    return fetch(API_GATEWAY + "/user", ToolsGet).then(
      (response: any) => {
        return response.json();
      }
    );
  },
  async updateClientProfile(body: any) {
    const options = {
      ...defaultTools,
      body: JSON.stringify(body),
      method: 'PATCH'
    }
    return fetch(API_GATEWAY + "/checkout/attachments/profile", options).then(
      (response: any) => {
        return response.json();
      }
    );
  }
};

export default CheckOut;
